// ==== 处理adm的样式覆盖 ====
.adm-auto-center-content {
  word-break: keep-all;
}

.adm-toast-mask {
  .adm-toast-main {
    max-width: 60%;
  }
}
@root-entry-name: default;
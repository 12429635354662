html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  // font-feature-settings: "tnum", "tnum";

}

body {
  background-color: #fafbfc !important;
}

.container {
  background-color: transparent;
  position: relative;
  height: auto;
  min-height: 100%;
  margin: 0;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

// .container .header {
//   height: 50px;
//   background: #0000FF;
// }

// .container .push {
//   padding-bottom: 100px;
// }

.footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: #fff;
  border-top: #EBEBEB 1px solid;
}


.red {
  color: var(--adm-color-danger);
}

.green {
  color: var(--adm-color-success);
  // color: #00b578;
}

.blue {
  color: blue;
}

// label {
//   margin-right: .4rem;
// }
@root-entry-name: default;
.stat-block {
  margin: 8px;

  // padding: 0 8px;
  :global {
    .adm-card {

      // background-color: rgb(203, 236, 160);
      .adm-card-header {
        justify-content: center;
      }

      .adm-card-header-title {
        // font-size: 0.8rem;
        line-height: 0.2;
      }

      .adm-card-body {
        text-align: center;
        // font-size: 1.2em;
      }
    }
  }
}

.info-block {
  label {
    color: var(--adm-color-weak);
    font-size: var(--adm-font-size-main);
    display: block;
  }

  span {
    text-align: center;
  }
}

.order-list {
  --prefix-width: 42px;
}


.daily-log-list {

  :global {
    // .adm-list-item-title {
    //   color: var(--adm-color);
    // }
    .adm-grid-item {
      text-align: center;
    }

    label {
      display: block;
      color: var(--adm-color-weak);
    }
  }


}
@root-entry-name: default;